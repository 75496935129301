const interval = 2000;

const animateTitle = () => {
  const titleList = document.querySelectorAll(".title");
  setInterval(() => {
    console.log('change color');
    const index1 = Math.floor(titleList.length * Math.random());
    const index2 = Math.floor(titleList.length * Math.random());
    const index3 = Math.floor(titleList.length * Math.random());
    titleList[index1].style.color = "#ffffff" ;
    titleList[index2].style.color = "#ffffff" ;
    titleList[index3].style.color = "#ffffff" ;
    setTimeout(() => {
      titleList[index1].style.color = "transparent" ;
      titleList[index2].style.color = "transparent" ;
      titleList[index3].style.color = "transparent" ;
    }, interval)
  }, interval)
}

animateTitle();